import React, { useState, useContext } from "react"
import { Link, navigate } from "gatsby"
import { TermsContext } from "../context/provider"
import { css } from "@emotion/core"

import SEO from "../components/seo"
import Layout from "../components/layout"
import OrderedListItem from "../components/OrderedListItem"

import { theme } from "../styles/theme"

const Terms = ({ location }) => {
  const termsContext = useContext(TermsContext)

  const [termsRead, setTermsRead] = useState(false)

  const handleScroll = ({ nativeEvent }) => {
    const threshold = 50
    const { target } = nativeEvent
    let isNearBottom =
      Math.ceil(target.scrollHeight - target.scrollTop) - target.clientHeight <=
      threshold

    if (isNearBottom && !termsRead) {
      setTermsRead(true)
    }
  }

  return (
    <Layout homeLink={false} isTermsPage>
      <SEO title="Terms" />

      <div css={styles.mainContainer}>
        {location.state && location.state.timeOut && (
          <div css={theme.components.row}>
            <p css={[theme.components.title, theme.components.titleSmall]}>
              Sorry, your session has ended. Please accept the Terms of Use to
              continue.
            </p>
          </div>
        )}

        <article css={theme.components.row} onScroll={handleScroll}>
          <section css={styles.termsContainer} className="theme">
            <h2 css={[theme.components.title, theme.components.titleLarge]}>
              NHS Blood Assist - Terms of Use
            </h2>

            <ol className="custom-ordered-list">
              <OrderedListItem number="1">
                This agreement <strong>(“Agreement”)</strong> sets out the Terms
                of Use <strong>(“Terms of Use”)</strong> of the NHS Blood
                Assist Website <strong>(“Website”)</strong>. It constitutes a
                legally binding agreement by and between you (personal and
                organisational “you”) and NHS Blood and Transplant{" "}
                <strong>(“NHSBT”/ “we”)</strong> concerning your use of the
                Website.
              </OrderedListItem>
              <OrderedListItem number="2">
                You understand these Terms of Use, agree that your use of the
                Website shall constitute acceptance of the Terms of Use and
                acknowledge our right to update the Terms of Use at any time
                without notice and to be bound by any updates.
              </OrderedListItem>
            </ol>

            <h3 css={[theme.components.title, theme.components.titleSmall]}>
              What Blood Assist is for
            </h3>
            <ol className="custom-ordered-list">
              <OrderedListItem number="3" isBold isRed>
                The Website is not to be used in connection with patients under 18
                years of age.
              </OrderedListItem>

              <OrderedListItem number="4">
                The Website comprises the mobile software application
                accompanying this Agreement, including without limitation any
                software code, scripts, interfaces, graphics, displays, text,
                documentation and other components, and any updates,
                modifications or enhancements to them.
              </OrderedListItem>

              <OrderedListItem number="5">
                The Website is intended for the sole purpose of assisting
                clinical practice in the administration of blood components
                within healthcare institutions in England by appropriately
                trained and competent, registered and regulated professionals.
              </OrderedListItem>

              <OrderedListItem number="6">
                The Website must not be used as a substitute for professional
                judgement or local organisational policies or procedures.
              </OrderedListItem>

              <OrderedListItem number="7">
                Users should recognise that some patients may misinterpret
                bedside smartphone use and should, therefore, explain to
                patients why they are using the smartphone in this way. You may
                tell them that the Website is not designed for the input of
                patient-identifying data and, at your discretion, inform them of
                its indicated use.
              </OrderedListItem>
            </ol>

            <h3 css={[theme.components.title, theme.components.titleSmall]}>
              Liability
            </h3>

            <ol className="custom-ordered-list">
              <OrderedListItem number="8">
                The Website, and the information contained in it, is provided to
                you "as is" with no guarantee of completeness or accuracy, and
                without warranty of any kind, express or implied, including but
                not limited to warranties of performance, merchantability and
                fitness for a particular purpose.
              </OrderedListItem>

              <OrderedListItem number="9">
                You acknowledge that Website functionality may be seriously
                affected by changes to the operating system of your device. You
                must not use the Website following an operating system change
                unless it is a version validated for use with the updated
                operating system.
              </OrderedListItem>

              <OrderedListItem number="10">
                Neither NHSBT nor its employees or management shall be liable
                for any damages (including without limitation, direct, indirect,
                consequential or incidental damages) resulting from or in
                connection with:
              </OrderedListItem>

              <ol className="custom-ordered-list">
                <OrderedListItem number="10.1">
                  continued use of the Website where the user has not accepted
                  updates;
                </OrderedListItem>
                <OrderedListItem number="10.2">
                  any information, materials, or recommendations related to the
                  Website;
                </OrderedListItem>
                <OrderedListItem number="10.3">
                  matters beyond NHSBT’s control, including without limitation
                  the incorrectness or incompleteness of input data and
                  information provided on third party websites that may be
                  linked to, directly or indirectly, by means of the Website;
                </OrderedListItem>
                <OrderedListItem number="10.4">
                  any software or hardware used for electronic communications;
                </OrderedListItem>
                <OrderedListItem number="10.5">
                  any other Website on your device;
                </OrderedListItem>
                <OrderedListItem number="10.6">
                  use of the Website with any incompatible operating system;
                </OrderedListItem>
                <OrderedListItem number="10.7">
                  the disabling of the Website (for example, following updating
                  your device’s operating system);{" "}
                </OrderedListItem>
                <OrderedListItem number="10.8">
                  termination of this Agreement;
                </OrderedListItem>
                <OrderedListItem number="10.9">
                  cessation of Website function; or
                </OrderedListItem>
                <OrderedListItem number="10.10">
                  the failure or delay in the delivery of electronic data, the
                  interception or manipulation of electronic data by third
                  parties and transmission and the impact of software viruses.
                </OrderedListItem>
              </ol>

              <OrderedListItem number="11">
                Nothing in this agreement excludes or limits liability for death
                or personal injury.
              </OrderedListItem>
            </ol>

            <h3 css={[theme.components.title, theme.components.titleSmall]}>
              Intellectual Property
            </h3>

            <ol className="custom-ordered-list">
              <OrderedListItem number="12">
                Unless otherwise stated, all rights, including copyrights and
                other intellectual property rights in the Website and all
                database rights in underlying databases and subsisting in
                information collected from you by NHSBT are the property of
                NHSBT.
              </OrderedListItem>
              <OrderedListItem number="13">
                Subject to data protection obligations, you may consult the
                Website and the information contained therein and record Website
                outputs (for example, using your device’s print screen
                function). However, any other use of the Website or such
                information, for example the storage or reproduction of the
                Website or any part of it in any other medium or the creation of
                links, hyperlinks or deeplinks between the Website and any other
                medium, is prohibited without NHSBT’s explicit written
                permission.
              </OrderedListItem>
            </ol>

            <h3 css={[theme.components.title, theme.components.titleSmall]}>
              Privacy
            </h3>
            <ol className="custom-ordered-list">
              <OrderedListItem number="14">
                To create statistical reports about Website use, anonymous data
                generated from users, including you, are collected by means of a
                software development kit (“SDK”) placed on your phone or device
                by Firebase's web server. The SDK contains an anonymous, unique
                sequence that is only accessible by the Google Firebase web
                server. The SDK saves anonymous data about your Website use
                until your device is connected to the internet, when it sends it
                to the Firebase web server. The server collates your data with
                equivalent data from other users. No personal data is sent, and
                the anonymous data will only be accessible to NHSBT and the
                developers of the Website, HMA Digital Marketing of 42 Sackville
                Street, Barnsley, S70 2DB.
              </OrderedListItem>
              <OrderedListItem number="15">
                The Website uses Google Firebase Analytics SDK's, Googles privacy
                policy is available at:{" "}
                <a href="http://www.google.com/policies/privacy/">
                  http://www.google.com/policies/privacy/
                </a>
                .
              </OrderedListItem>
            </ol>

            <h3 css={[theme.components.title, theme.components.titleSmall]}>
              Termination
            </h3>
            <ol className="custom-ordered-list">
              <OrderedListItem number="16">
                NHSBT has the right to terminate this Agreement at any time
                without notice.
              </OrderedListItem>
              <OrderedListItem number="17">
                You can terminate this agreement by uninstalling the Website,
                which you may do at any time.
              </OrderedListItem>
            </ol>

            <h3 css={[theme.components.title, theme.components.titleSmall]}>
              Law and Jurisdiction
            </h3>
            <ol className="custom-ordered-list">
              <OrderedListItem number="18">
                These Terms of Use shall be governed by and construed in
                accordance with the laws of England and Wales and subject to the
                exclusive jurisdiction of the English courts, which shall settle
                all disputes arising in connection with these Terms and
                Conditions of Use.
              </OrderedListItem>
            </ol>
            <p css={styles.copyrightText}>
              NHSBT Copyright &copy;2020. All rights reserved. Designated
              trademarks are the property of their owners.
            </p>
          </section>

          <div css={styles.buttonContainer}>
            <Link
              to="/"
              css={
                !termsRead
                  ? [theme.components.button, styles.buttonDisabled]
                  : theme.components.button
              }
              onClick={() => {
                termsContext.acceptTerms()
                navigate("/")
              }}
            >
              Accept Terms of Use
            </Link>
          </div>
        </article>
      </div>
    </Layout>
  )
}

const styles = {
  mainContainer: css({
    background: theme.colours.lightGrey,
    paddingTop: theme.gapDouble,
    height: "100%",
  }),
  termsContainer: css({
    backgroundColor: "#fff",
    padding: theme.gapDouble,
    borderRadius: theme.borderRadius,
    height: "700px",
    maxHeight: "70vh",
    overflowY: "scroll",
    marginBottom: theme.gap,
  }),
  copyrightText: css({
    fontSize: "0.9rem",
    marginTop: theme.gap,
  }),
  buttonContainer: css({
    display: "flex",
    justifyContent: "flex-end",
  }),
  buttonDisabled: css({
    pointerEvents: "none",
    backgroundColor: theme.colours.darkGrey,
    opacity: 0.6,
    cursor: "not-allowed",
  }),
}

export default Terms
